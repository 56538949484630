<template>
  <div>
    <van-nav-bar v-if="showBanner" left-arrow @click="$emit('onBack')">
      <template #title>
        <h1>{{ pageTitle }}</h1>
      </template>
    </van-nav-bar>
    <div v-if="!!categories?.length" :class="[isScrollTrigger ? 'pb-0' : 'pb-2']"
         class="flex gap-3 p-4 overflow-x-auto hide-scrollbar transition-all items-start">
      <div v-for="(category, index) in categories"
           :key="`tab-${index}`"
           :class="[isScrollTrigger ? 'gap-3 flex-col' : 'bg-bright-grey p-1',
           !isScrollTrigger && !isDynamicCategory && ((index == 0 && active == -1) || (index == active && active >= 0))  ? 'active-cat outline outline-1 outline-[#878787]' :'']" class="transition-all duration-250 flex-center text-center rounded-full"
           @click="setCategory({category,index})">


        <div :class="[isScrollTrigger ? 'w-16 h-16 border-2 outline outline-2 border-white outline-white  overflow-hidden' : 'w-4 h-4',
             (index == 0 && active == -1)  ? 'overflow-hidden' :'',
             isScrollTrigger && !isDynamicCategory && ((index == 0 && active == -1) || (index == active && active >= 0)) ? '!outline-[#878787] active-cat' :'']"
             class="bg-bright-grey transition-all duration-250 relative rounded-full flex-center overflow-hidden">
          <niceone-image v-if="!category.isAllProduct" :src="category.image || category.image_url"
                         class="absolute object-cover w-full h-full"/>
          <niceone-image :class="[isScrollTrigger ? 'w-10' : 'w-5']" :src="category.image"/>
        </div>

        <div
            :class="[isScrollTrigger ? 'font-niceone-normal text-wrap ':'px-2 w-max text-nowrap block',
            (index == 0 && active == -1) || (index == active && active >= 0) ? '!text-black !font-niceone-medium' :'']"
            class="text-xs text-[#4E4E4E] van-multi-ellipsis--l2">
          {{ category.name }}
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  categories: {
    type: Array,
    default: []
  },
  active: {
    type: Number,
    default: 0
  },
  pageTitle: {
    type: String,
    default: ''
  },
  isPlpBannner: {
    type: Boolean,
    default: false
  },
  isDynamicCategory: {
    type: Boolean,
    default: false
  }
})


const emit = defineEmits('clicktab')

const setCategory = ({category, index}) => emit('clicktab', {name: index, seo_url: category?.seo_url})

const isScrollTrigger = ref(true)
const onScrollTriggerValue = ref(0)
const scrollSkip = ref(200)


const pageScrollValue = ref(0)

const logScroll = () => {
  onScrollTriggerValue.value = !props?.isPlpBannner ? 100 : 380
  pageScrollValue.value = window.scrollY
  isScrollTrigger.value = window.scrollY > onScrollTriggerValue.value ? false : true
}

const showBanner = computed(() => {
  if (!props?.isPlpBannner) return true
  if (props?.isPlpBannner && pageScrollValue.value > onScrollTriggerValue.value) return true
})

watch(isScrollTrigger, () => {
  if (!isScrollTrigger.value) window.scrollTo(0, window.scrollY + scrollSkip.value);
  if (isScrollTrigger.value) window.scrollTo(0, window.scrollY - scrollSkip.value);
  scrollToElement('.active-cat')
})


onMounted(() => scrollToElement('.active-cat'))

const scrollToElement = (toElement) => {
  setTimeout(() => {
    let element = document.querySelector(toElement)
    element?.scrollIntoView({
      block: 'nearest', inline: 'center'
    });

  }, 500);
}

onMounted(() => window.addEventListener('scroll', logScroll))
onBeforeUnmount(() => window.removeEventListener('scroll', logScroll))
</script>
