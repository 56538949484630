<template>
  <div v-if="description" class="bg-white rounded-lg w-[250px] mt-4 p-4 inherit-style">
    <h3 v-if="title" v-html="title"/>
    <div v-if="description" class="text-xs text-mirage max-h-[200px] overflow-y-auto pe-2" v-html="description"></div>
  </div>
</template>

<script setup>
const props = defineProps({
  meta: {
    type: Object,
    default: () => ({})
  }
});
const title = computed(() => props.meta?.title || props.meta?.meta_title)
const description = computed(() => props.meta?.description || props.meta?.meta_description)
</script>

<style scoped>
</style>

