<template>
  <div class="bg-white rounded-lg">

    <div class="flex items-center justify-between p-3 border-b">
      <div class="font-niceone-medium text-lg">{{ $t('category.filter.filter_by') }}</div>
      <div class="text-secondary cursor-pointer" @click="onClear">{{ $t('common.clear') }}</div>
    </div>

    <filter-item :items="filters.manufacturers" :title="$t('menu.brands')" collapse
                 name="manufacturer_id" queryKey="manufacturers" @update="onFilterUpdate">
      <template #title>
        <div class="flex items-center gap-2">
          <div class="text-base font-niceone-medium">
            <span class="text-sm">{{ $t('menu.brands') }}</span>
          </div>
          <van-badge :dot="isBrandActive(filters.manufacturers)"
                     :position="$i18n.locale === 'en' ? 'top-left' : 'top-right'"/>
        </div>
      </template>
    </filter-item>

    <filter-item v-for="(item, _) in sortedAttributes" :key="`filter-${item?.attribute_group_name}`"
                 :collapse="isActiveGroup(item.attributes)" :items="item.attributes" name="attribute_id"
                 queryKey="filters" @update="onFilterUpdate">
      <template #title>
        <div class="flex items-center gap-2">
          <div class="text-base font-niceone-medium">
            <span class="text-sm" v-html="item?.attribute_group_name"></span>
          </div>
          <van-badge :dot="isActiveGroup(item.attributes)"
                     :position="$i18n.locale === 'en' ? 'top-left' : 'top-right'"/>
        </div>
      </template>
    </filter-item>

  </div>
</template>

<script setup>
import FilterItem from "@/components/category/desktop/FilterItem";

const props = defineProps({
  filters: {
    type: Object,
    default: {}
  }
})

const emit = defineEmits(['update:products', 'update:page'])

const onFilterUpdate = () => {
  emit('update:products', [])
  emit('update:page', 1)
  window?.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth"
  });
}

const router = useRouter()
const route = useRoute()
const onClear = () => {
  onFilterUpdate()
  router.push({path: route.path})
}

const isBrandActive = (filters = null) => !!filters?.find(item => route.query?.manufacturers?.includes(item.manufacturer_id))
const isActiveGroup = (filters = null) => !!filters?.find(item => route.query?.filters?.includes(item.attribute_id))

// Computed property to reorder selected filter groups to the top
const sortedAttributes = computed(() => {
  const selected = [];
  const unselected = [];

  props.filters?.attributes?.forEach((item) => {
    if (isActiveGroup(item.attributes)) {
      selected.push(item);
    } else {
      unselected.push(item);
    }
  });

  return [...selected, ...unselected];
});
</script>