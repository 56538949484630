<template>
  <device-wrapper #default="{ desktop }">
    <products-desktop v-if="desktop"/>
    <products-mobile v-else/>
  </device-wrapper>
</template>

<script setup>
import ProductsDesktop from "@/components/products/desktop/index"
import ProductsMobile from "@/components/products/mobile/index"

const route = useRoute()

// Check if query params has more then 1 Question mark (?) then redirect to valid link
if ((route?.fullPath?.match(/\?/g) || [])?.length > 1) await navigateTo(route?.path, {redirectCode: 301});
</script>