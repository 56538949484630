<template>
  <div class="w-full h-96 relative p-4 overflow-hidden flex flex-col justify-between items-start">
    <div class="w-11 h-11 bg-white rounded-full flex-center z-1" @click="$emit('onBack')">
      <niceone-image :src="$publicPath('/images/categories/arrow.svg')" width="14" class="flip-icon-ltr"/>
    </div>

    <div class="text-white z-1">
      <h1 class="font-bold text-2xl pb-2">{{ bannerData?.title }}</h1>
      <div class="font-niceone-medium leading-5 van-multi-ellipsis--l3">
        {{ bannerData?.description }}
      </div>
    </div>

    <div class="absolute inset-0 pointer-events-none">
      <niceone-image v-if="bannerData?.image_url" :src="bannerData?.image_url" class="object-cover w-full h-full"/>
      <div class="bg-gradient-to-b from-transparent to-black/50  absolute w-full h-1/2 left-0 bottom-0 "></div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  banner: {
    type: Object,
    default: () => {
    }
  }
})

const innerComponentId = ref(20)
const bannerData = computed(() => props?.banner?.data.find((data) => data?.type == innerComponentId.value))
</script>