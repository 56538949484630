<template>
  <van-badge :dot="hasFilters" class="pl-1" position="top-left">
    <div class="whitespace-nowrap bg-white rounded-full px-2 py-1 cursor-pointer" @click="openList()">
      <span class="text-sm">{{ title }}</span>
      <van-icon class="ltr:ml-2 rtl:mr-2" name="arrow-down" size="14"></van-icon>
    </div>
  </van-badge>

  <van-radio-group v-model="checked">
    <van-popup v-model:show="showList" :style="{ height: '45%' }" class="flex flex-col relative overflow-y-none"
               position="bottom">


      <div class="flex flex-col h-full relative">
        <van-nav-bar :title="title">
          <template #left>
            <van-icon name="arrow-down" size="18" @click="reset"></van-icon>
          </template>
          <template #right>
            <button class="text-sm" @click="reset(false)">{{ $t('common.reset') }}</button>
          </template>
        </van-nav-bar>

        <div class="overflow-y-auto h-full">
          <van-cell v-for="(item, index) in productSortOptions()" :key="`${title}-${index}`">
            <template #title>
              <van-radio :name="item.value">{{ item.name }}</van-radio>
            </template>
          </van-cell>
        </div>

        <div class="m-3">
          <van-button block type="primary" @click="apply">{{ $t('common.apply') }}</van-button>
        </div>
      </div>
    </van-popup>
  </van-radio-group>
</template>

<script setup>
import {productSortOptions} from "@/helper/type";

const {t} = useI18n()
const title = t('common.sort')

const showList = ref(false)
const checked = ref('')

const router = useRouter()
const route = useRoute()

const apply = () => {
  router.push({
    path: route.path,
    query: {...route.query, sort: checked?.value},
  })
  reset()
}

const openList = () => {
  showList.value = true
  checked.value = route?.query?.sort || ''
}

const hasFilters = computed(() => !!route?.query?.sort)

const reset = (closeDialog = true) => {
  showList.value = !closeDialog
  checked.value = ''
}
</script>