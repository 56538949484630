import {getFormattedWebsitePath} from "@/utilities";

export default ({data = {}, products = [], title = ''} = {}) => {

    const {meta_title = '', meta_description = ''} = data || {}

    const route = useRoute()

    const {getRobotsMeta, getLinks} = useSeoMeta()

    const currentYear = new Date().getFullYear()

    const {t} = useI18n()

    const formattedMetaTitle = meta_title || t('seo.category_title_template', {name: title})
    const formattedMetaDescription = meta_description || t('seo.category_description_template', {name: title})

    return {
        meta: [
            {
                name: 'description',
                content: formattedMetaDescription
            },
            //   // Twitter
            {name: 'twitter:card', content: 'article.group'},
            {
                name: 'twitter:title',
                content: formattedMetaTitle
            },
            {
                name: 'twitter:description',
                content: formattedMetaDescription
            },
            {name: 'twitter:site', content: '@niceonesa'},
            {
                name: 'twitter:image',
                content:
                    'https://d3e7ardzpaj3y4.cloudfront.net/image/theme/images/logo-social.png'
            },

            {name: 'rating', content: 'General'},
            {name: 'Classification', content: 'Business'},
            {
                name: 'copyright',
                content: `Copyright © ${currentYear} NICE ONE || all right reserved`
            },
            {property: 'og:type', content: 'product.group'},
            {
                property: 'og:title',
                content: formattedMetaTitle
            },
            {
                property: 'og:description',
                content: formattedMetaDescription
            },
            {
                property: 'og:image',
                itemprop: 'image',
                content:
                    'https://s3-eu-west-1.amazonaws.com/niceonesa-prd-s3-files/assets/images/favicon.png'
            },
            {
                property: 'og:image:alt',
                content:
                    'https://s3-eu-west-1.amazonaws.com/niceonesa-prd-s3-files/assets/images/favicon.png'
            },
            {
                property: 'og:site_name',
                content: 'نايس ون'
            },
            {
                property: 'og:url',
                content: getFormattedWebsitePath(route.fullPath)
            },
            {
                property: 'og:email',
                content: 'info@niceonesa.com'
            },
            {
                property: 'og:phone_number',
                content: '920033385'
            },
            {property: 'product:condition', content: 'new'},
            {property: 'product:price:currency', content: 'SAR'},
            {
                name: 'category',
                content: formattedMetaTitle
            },
            {
                name: 'directory',
                content: route.path
            },
            ...getRobotsMeta({notFound: !products?.length}),
        ],
        link: () => [...(getLinks({notFound: !products?.length}) || [])]
    }
}
