const BRAND_SLUGS = {
    YSL: 'ysl',
    LANCOME: 'lancome',
    GIORGIO_ARMANI: 'giorgio-armani'
}
export const BRANDS_DATA_BY_SLUG = {
    [BRAND_SLUGS.YSL]: {
        image: '/images/brand-banners/ysl.png',
        title_en: 'YSL',
        title_ar: 'ايف سان لوران',
        description_en: 'Innovation Beyond the Ordinary!\n Where innovation transcends the expected. YSL perfumes, crafted with artistic vision, offer a luxurious and distinctive fragrance experience.',
        description_ar: 'ابتكار خارج عن المألوف !\n' +
            'تتميز عطور إيف سان لوران بلمسة فنية راقية، مما يجعلها الخيار الأمثل لمن يبحثون عن عبير فاخر وفريد من نوعه.'
    },
    [BRAND_SLUGS.LANCOME]: {
        image: '/images/brand-banners/lancome.png',
        title_en: 'Lancome',
        title_ar: 'لانكوم',
        description_en: 'Unique Fragrances Born in France. Inspired by the Lancôme forest, exquisite perfumes for men and women captivate the world with their exceptional compositions.',
        description_ar: 'من أعماق غابة النكوسمي في قلب فرنسا،\n' +
            'انطلقت عطور لانكوم نحو العالم بتركيباتها الرائعة عبر زجاجة عطر غير مكرر على الاطلاق !'
    },
    [BRAND_SLUGS.GIORGIO_ARMANI]: {
        image: '/images/brand-banners/giorgio-armani.png',
        title_en: 'Giogrio Armani',
        title_ar: 'جورجيو ارماني',
        description_en: 'Experience Elegance in Every Scent ! Discover exquisite perfumes from Milan, Italy, crafted with Italian artistry and sophistication.',
        description_ar: 'اكتشف عطرك الأنيق !\n' +
            'من ميلانو، قلب الموضة الإيطالية، نقدم لك مجموعة من العطور الفاخرة المصنوعة بحرفية عالية، لتمنحك تجربة عطرية لا تُنسى.'
    }
}