<template>
  <van-badge :dot="hasFilters" position="top-left">
    <div class="whitespace-nowrap bg-white rounded-full px-2 py-1 cursor-pointer" @click="openList()">
      <span class="text-sm" v-html="title"></span>
      <van-icon class="ltr:ml-2 rtl:mr-2" name="arrow-down" size="14"></van-icon>
    </div>
  </van-badge>

  <van-checkbox-group v-model="checked">
    <van-popup v-model:show="showList" :style="{ height: '50%' }" class=" overflow-y-none" position="bottom"
               @close="reset">

      <div class="flex flex-col h-full relative">
        <van-nav-bar :title="title">
          <template #left>
            <van-icon name="arrow-down" size="18" @click="reset"></van-icon>
          </template>
          <template #right>
            <button class="text-sm" @click="reset(false)">{{ $t('common.reset') }}</button>
          </template>
        </van-nav-bar>

        <niceone-search v-model="search" :placeholder="$t('search.search')" clearable/>

        <div v-if="!getItems.length" class="text-boulder px-3">{{
            $t('category.filter.no_filters_for', {word: search})
          }}
        </div>

        <div class="overflow-y-auto h-full">
          <van-cell v-for="(item, index) in getItems" :key="`${title}-${index}`">
            <template #title>
              <van-checkbox :name="item[name]">{{ item.name }}</van-checkbox>
            </template>
          </van-cell>
        </div>

        <div class="m-3">
          <van-button block type="primary" @click="apply">{{ $t('common.apply') }}</van-button>
        </div>

      </div>
    </van-popup>
  </van-checkbox-group>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  items: {
    type: Array,
    default: () => []
  },
  queryKey: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  }
})

const emit = defineEmits(['update'])

const search = ref('')

const showList = ref(false)
const checked = ref([])

const router = useRouter()
const route = useRoute()

const apply = () => {
  router.push({
    path: route.path,
    query: {...route.query, [props.queryKey]: checked?.value?.join(',')},
  })
  emit('update')
  reset()
}

const queryArray = computed(() => route?.query[props.queryKey]?.split(',')?.map(Number) || [])

const getItems = computed(() => {
  return props.items
      .filter(item => item.name.toLowerCase().includes(search.value.toLowerCase()))
      .sort((a, b) => checked.value?.includes(b[props.name]) - checked.value?.includes(a[props.name]));
});

const openList = () => {
  showList.value = true
  checked.value = route.query[props.queryKey] ? queryArray.value : []
}

const hasFilters = computed(() => {
  if (!route.query[props.queryKey]) return false
  return !!props.items.find(item => queryArray.value.includes(item[props.name]))
})

const reset = (closeDialog = true) => {
  showList.value = !closeDialog
  checked.value = checked.value.filter(item => !props.items.some(i => i[props.name] === item));
  search.value = ""
}
</script>