import {useCategoryStore} from "@/store/category";

export const customBackNavigation = () => {
    const router = useRouter()

    const categoryStore = useCategoryStore()
    const {openPageFrom} = storeToRefs(categoryStore)

    const page = openPageFrom.value
    openPageFrom.value = ""

    switch (page) {
        case 'category': {
            return router.push(useNuxtApp().$routerUrl('/categories'))
        }
        case 'brands': {
            return router.push(useNuxtApp().$routerUrl('/brands'))
        }
        case 'home': {
            return router.push(useNuxtApp().$routerUrl('/'))
        }
        default: {
            return router.back()
        }
    }
};