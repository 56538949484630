<template>
  <div class="flex">
    <nuxt-link v-for="i in getTotalPages" :to="getFormattedPageUrl(i)">{{ i }}</nuxt-link>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  itemsPerPage: {
    type: Number,
    default: 30
  },
  totalItems: {
    type: Number,
    default: 0
  }
})

const getTotalPages = computed(() => Math.floor(props.totalItems / props.itemsPerPage))

const route = useRoute()
const getFormattedPageUrl = (pageNo = 1) => {
  const {$routerUrl} = useNuxtApp()

  const {page, ...otherParams} = route.query

  const queryParams = Object.entries(otherParams)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&')

  return $routerUrl(`${route.path}?page=${pageNo || 1}&${queryParams}`)
}
</script>

<style scoped>

</style>