<template>
  <div ref="scrollContainer" class="flex flex-nowrap overflow-x-auto gap-1 py-2 pb-3 hide-scrollbar">

    <sort-filter/>

    <filter-item :items="filters?.manufacturers" :title="$t('menu.brands')" name="manufacturer_id"
                 queryKey="manufacturers"/>

    <filter-item v-for="(item, index) in sortedAttributes" :key="`filter-${index}`" :items="item?.attributes"
                 :title="item?.attribute_group_name" name="attribute_id" queryKey="filters" @update="onFiltersUpdate"/>
  </div>
</template>

<script setup>
import SortFilter from "@/components/category/mobile/SortFilter";
import FilterItem from "@/components/category/mobile/FilterItem";
import {useScroll} from '@vueuse/core'

const props = defineProps({
  filters: {
    type: Object,
    default: {}
  }
})

const scrollContainer = ref(null)
const {x: scrollX} = useScroll(scrollContainer, {behavior: 'smooth'})

const onFiltersUpdate = () => {
  scrollX.value = 0
}

const route = useRoute()

const isActiveGroup = (filters = null) => !!filters?.find(item => route.query?.filters?.includes(item.attribute_id))

// Computed property to reorder selected filter groups to the top
const sortedAttributes = computed(() => {
  const selected = [];
  const unselected = [];

  props.filters?.attributes?.forEach((item) => {
    if (isActiveGroup(item.attributes)) {
      selected.push(item);
    } else {
      unselected.push(item);
    }
  });

  return [...selected, ...unselected];
});
</script>