import {useCurrencyStore} from "@/store/currency";
import {getFormattedWebsitePath} from "@/utilities"

export default ({products = [], title = null} = {}) => {

    // Current locale
    const {locale} = useI18n()

    // Current currency
    const currencyStore = useCurrencyStore()
    const {currency} = storeToRefs(currencyStore)

    const {$routerUrl} = useNuxtApp()

    const route = useRoute()

    const itemListElement = products?.map((product, index) => {
        let product_details = {}

        const url = getFormattedWebsitePath(locale === 'ar' ? product.seo_url_ar : product.seo_url_en)

        const extra = {
            availability:
                'https://schema.org/' +
                (product?.has_stock ? 'InStock' : 'OutOfStock'),
            itemCondition: 'https://schema.org/NewCondition',
            priceCurrency: currency.value,
            url
        }

        if (product?.special && product?.special?.length) {
            const special = product.special[0]

            product_details.offers = {
                '@type': 'Offer',
                price: parseInt(special.price_formated),
                priceValidUntil: special.date_end,
                ...extra
            }
        } else {
            product_details = {
                price: product.price_formated?.split(' ')[0],
                ...extra
            }
        }

        return {
            '@type': 'Product',
            image: product.thumb,
            name: product.name,
            brand: {
                '@type': 'Brand',
                name: product.manufacturer,
                url: getFormattedWebsitePath(product.manufacturer?.toLowerCase()?.replace(' ', '-'))
            },
            category: {
                '@type': 'Thing',
                name: encodeURI(title),
                url: getFormattedWebsitePath(route.path)
            },
            ...product_details
        }
    })

    return {
        '@ntext': 'http://schema.org',
        '@type': 'ItemList',
        url: getFormattedWebsitePath(route.path),
        name: title,
        itemListOrder: 'Unordered',
        numberOfItems: products?.length || [],
        itemListElement
    }
}
