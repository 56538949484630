<template>
  <van-collapse v-model="collapseModel">
    <van-collapse-item :name="1" :title="title">
      <template #title>
        <slot name="title">
          <div class="text-base font-niceone-medium" v-html="title"></div>
        </slot>
      </template>
      <div class="mb-3">
        <niceone-search v-model="search" :placeholder="$t('category.filter.search_for', {name: title})" class="!p-0"
                        clearable/>
      </div>
      <div v-if="!getItems.length" class="text-boulder">{{ $t('category.filter.no_filters_for', {word: search}) }}</div>
      <van-checkbox-group v-model="checked" class="max-h-54 overflow-y-auto" shape="square">
        <van-checkbox v-for="(item, index) in getItems" :key="`filter${item.name}-${index}`" :name="item[name]"
                      class="mb-2" icon-size="16">
          {{ item.name }}
        </van-checkbox>
      </van-checkbox-group>
    </van-collapse-item>
  </van-collapse>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  items: {
    type: Array,
    default: () => []
  },
  queryKey: {
    type: String,
    default: ''
  },
  name: {
    type: String,
    default: ''
  },
  collapse: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['update'])

const router = useRouter()
const route = useRoute()

const queryArray = computed(() => route?.query[props.queryKey]?.split(',')?.map(Number) || [])
const checked = computed({
  get: () => queryArray.value,
  set: (keys) => {
    emit('update')
    const updatedKeys = queryArray.value?.filter(val => !getItems.value?.map(item => item[props?.name]).includes(val))
    const uniqueKeys = [...new Set([...updatedKeys, ...keys])]

    router.push({
      path: route.path,
      query: {
        ...route.query,
        [props.queryKey]: uniqueKeys?.length ? uniqueKeys.join(',') : null
      },
    });
  }
});

const collapseModel = ref([props.collapse ? 1 : 0])

const search = ref('')
const getItems = computed(() => {
  return props.items
      .filter(item => item.name.toLowerCase().includes(search.value.toLowerCase()))
      .sort((a, b) => checked.value?.includes(b[props.name]) - checked.value?.includes(a[props.name]));
});
</script>