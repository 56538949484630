<template>
  <layout-wrapper :isFooter="true" class="bg-light-grey">
    <client-only>
      <full-page-loader v-if="page === 1" :loading="loading"/>
    </client-only>

    <product-banner v-if="!!isPlpBannner" :banner="isPlpBannner" @onBack="onBack"/>
    <div class="w-full sticky top-0 z-30 bg-light-grey">
      <template v-if="getSearch">
        <div class="flex items-center bg-white mb-2">
          <van-icon class="ms-2 flip-icon" name="arrow-left" size="20" @click="onBack"></van-icon>
          <home-search :initial-value="getSearch"/>
        </div>
      </template>
      <div class="bg-light-grey">
        <div v-if="!getSearch && !categories.length">
          <van-nav-bar v-if="!dynamicCategoryComponent" left-arrow @click="onBack">
            <template #title>
              <h1>{{ getPageTitle }}</h1>
            </template>
          </van-nav-bar>
          <category-filters v-else :active="activeTab" :categories="dynamicCategoryComponent"
                            :isDynamicCategory="!!dynamicCategoryComponent"
                            :isPlpBannner="!!isPlpBannner" :page-title="getPageTitle" @clicktab="onClickTab"
                            @onBack="onBack"/>
        </div>

        <category-filters v-if="categories.length" :active="activeTab" :categories="categories"
                          :isPlpBannner="!!isPlpBannner" :page-title="getPageTitle" @clicktab="onClickTab"
                          @onBack="onBack"/>
        <filters :filters="data?.filters" class="px-2 ps-1"/>
      </div>
    </div>

    <client-only>
      <dynamic-components v-if="!!data?.components?.length" :components-data="data?.components || []"/>
    </client-only>

    <category-not-found v-if="!getProducts?.length && !data?.components?.length" :loading="loading"
                        :products="getProducts"/>

    <div v-if="getProducts?.length" class="px-3 pb-3">
      <div class="grid gap-3 lg:grid-cols-6 md:grid-cols-4 grid-cols-2">
        <product v-for="(product, index) in products" :key="`product-${index}`" :product="product"
                 :source-data="setEventsData(product)" @productClicked="productClicked(product,index)"/>
      </div>

      <infinite-load-more v-model="page" :items-length="products?.length" :loading="loading" :total="data?.total"/>

      <!-- For SEO purpose only -->
      <div v-if="!loading && products?.length" class="hidden flex-center flex-col py-5">
        <seo-pagination :total-items="total"/>
        <meta-description :meta="data?.data" class="!w-full"/>
      </div>

    </div>
  </layout-wrapper>
</template>

<script setup>
import HomeSearch from "@/components/home/Search"
import Product from "@/components/Product";
import {useCategoryStore} from '@/store/category';
import Filters from "@/components/category/mobile/Filters";
import InfiniteLoadMore from "@/components/common/InfiniteLoadMore";
import {customBackNavigation} from "@/composables/useBackNavigation";
import {TRACKERS, EVENT_SOURCES} from "~/constants/events";
import {track} from "@/composables/useTrackEvent";
import CategoryHead from "@/head/category";
import CategoryJsonld from "@/jsonld/category"
import {useCustomFetch} from "@/composables/useFetch";
import CategoryNotFound from "@/components/category/NotFound";
import MetaDescription from "@/components/category/desktop/MetaDescription";
import SeoPagination from "@/components/common/SeoPagination";
import ProductBanner from "~/components/category/mobile/Banner.vue";
import CategoryFilters from "~/components/category/mobile/CategoryFilters";
import {BLACKLISTED_BRANDS_SLUG, DYNAMIC_COMPONENTS} from "@/constants";

const {t} = useI18n()
const route = useRoute()
const getSlug = computed(() => route.params.slugs.join('/'))

const getSeoUrl = computed(() => {
  const slugs = route.params.slugs.filter(Boolean)
  return slugs[slugs.length - 1]
})

const getSort = computed(() => route?.query?.sort || '')
const getManufacturerIds = computed(() => route?.query?.manufacturers || '')
const getAttributeIds = computed(() => route?.query?.filters || '')
const getSearch = computed(() => route?.query?.q || '')
const page = ref(Number(route.query?.page) || 1)
const metaData = computed(() => data.value?.data)

const {data, loading} = await useCustomFetch('?route=rest/product_admin/products', {
  seo_url: getSeoUrl.value,
  sort: getSort,
  manufacturer_ids: getManufacturerIds,
  attribute_ids: getAttributeIds,
  search: getSearch,
  page,
  limit: 30,
  first: false
})

const total = computed(() => data.value?.total)
const products = ref([])

const updateProducts = () => {
  products.value = page.value === 1 ? data.value?.products :
      [...products.value, ...data.value?.products || []]
}

const getProducts = computed(() => {
  if (BLACKLISTED_BRANDS_SLUG.includes(getSeoUrl.value)) return []
  return products.value
})

// Call updateProducts when appropriate (e.g., when `data` changes)
watch(data, () => {
  updateProducts()
}, {immediate: true})

const getPageTitle = computed(() => data.value?.category || getSearch.value || data.value?.manufcaturer)

const isPlpBannner = computed(() => data.value?.components?.find((component) => component?.type == DYNAMIC_COMPONENTS.PLP_BANNER))
const dynamicCategoryComponent = computed(() => data.value?.components?.find((component) => component?.type == DYNAMIC_COMPONENTS.CATEGORIES)?.data || [])

// Head schema
useHead({
  titleTemplate: (title) => title ? `${title} | ${t('seo.niceone_ksa')}` : t('seo.category_title_template', {name: getPageTitle.value}),
  title: () => data.value?.data?.meta_title,
  ...CategoryHead({data: data.value?.data, products: data.value?.products, title: getPageTitle.value})
})

// Json schema
useJsonld(CategoryJsonld({title: getPageTitle.value, products: products.value}))

const activeTab = computed(() => {
  dynamicCategoryComponent
  return categories?.value.findIndex(item => item.seo_name === getSlug.value)
})

const categoryStore = useCategoryStore()
const {categories: storeCategories, openPageFrom} = storeToRefs(categoryStore)

onMounted(() => {
  track(TRACKERS.OPEN_PRODUCT_LIST, {meta: metaData, page: page.value})
})

onUnmounted(() => {
  openPageFrom.value = 'products'
})

const findCategoriesBySeoName = (categories = [], seoName) => {

  return categories.flatMap(categoryItem => {
    if (categoryItem.seo_name === seoName) {
      // If the current category matches the given `seoName`, return an array
      // containing the category itself and its siblings (i.e., categories at
      // the same level of nesting)
      const allProductCategory = {
        name: t('common.all_products'),
        isAllProduct: true,
        categories: [],
        seo_url: `/${route.params.slugs.slice(0, -1).join('/')}`,
        image: categoryItem?.image
      };
      return categoryItem?.categories.length ?
          [
            allProductCategory,
            ...categoryItem.categories
          ] :
          [
            allProductCategory,
            ...categories
          ]
    } else {
      // If the current category doesn't match the `seoName`, recursively search
      // its child categories
      return findCategoriesBySeoName(categoryItem.categories, seoName);
    }
  });
}

// Call the function to get an array of categories with the given `seo_name`
const categories = computed(() => findCategoriesBySeoName(storeCategories.value, getSlug.value) || []);

// change tab
const router = useRouter()
const onClickTab = (value) => {

  if (!categories.value.length) {
    !!value?.seo_url && router.push(useNuxtApp().$routerUrl(value?.seo_url))
    return
  }

  // If current tab and click tab is All Products we need to prevent the click
  if (activeTab.value === -1 && !value.name) return

  router.push(useNuxtApp().$routerUrl(categories?.value[value.name].seo_url))
}


const onBack = () => {
  customBackNavigation()
}

const queries = computed(() => route.query)
// watch for query changes and set the page to 1
watch(
    () => queries,
    () => {
      page.value = 1
    },
    {deep: true}
)
const productClicked = (product, index) => {
  //track(TRACKERS.FILTER_INTERACTIONS, {eventAction: '', filters: data.filters})
  track(TRACKERS.PLP_INTERACTION, {eventAction: '', eventLabel: product.name, product: product})
  if (getSearch.value) {
    track(TRACKERS.ALGOLIA_PRODUCT_CLICK, {id: product.id, queryID: product.queryID, index})
  }
}


const setEventsData = (product) => {

  if (metaData.value?.type == 'manufacturer') {
    return {...EVENT_SOURCES.BRAND_PAGE, source_name: metaData.value?.en_name, source_id: metaData.value?.id}
  }

  if (categories?.value?.length) {
    return {
      ...EVENT_SOURCES.CATEGORY_PAGE,
      source_name: getSeoUrl.value,
      source_id: product.category_hierarchy,
      category_hierarchy: true
    }
  } else {
    return {...EVENT_SOURCES.PRODUCT_LISTING_PAGE, source_name: metaData.value?.en_name, source_id: metaData.value?.id}
  }

}
</script>